<template>
  <aside>
    <header>
      <h2>Competition Results</h2>
      <button class="close" @click="close"><span class="sr-only">Close modal window</span></button>
    </header>

    <main>
      <leaderboard v-if="leaderboard !== null" :competition="competition" :leaderboard="leaderboard" />
      <loading-indicator v-else />
    </main>
  </aside>
</template>

<script>
import Leaderboard from '@/components/Awards/Competitions/Leaderboard'

export default {
  props: ['data'],
  components: {
    Leaderboard
  },
  data() {
    return {
      competition: null,
      leaderboard: null
    }
  },
  created() {
    this.competition = _.cloneDeep(this.data.competition)
    this.leaderboard = _.cloneDeep(this.data.leaderboard)
  },
  methods: {
    close() {
      this.$emit('done')
    }
  }
}
</script>